import React, { useState, useEffect } from 'react';

const Reason = ({ formData, setFormData }) => {
  const [selectedReason, setSelectedReason] = useState(formData.reason);

  const handleReasonChange = (event) => {
    const selectedReason = event.target.value;
    setFormData({
      ...formData,
      reason: selectedReason,
    });
    setSelectedReason(selectedReason);
  };

  useEffect(() => {
    setSelectedReason(formData.reason);
  }, [formData.reason]);

  const [selectedTime, setSelectedTime] = useState(formData.sellingTime);

  const handleTimeChange = (event) => {
    const selectedTime = event.target.value;
    setFormData({
      ...formData,
      sellingTime: selectedTime,
    });
    setSelectedTime(selectedTime);
  };

  useEffect(() => {
    setSelectedTime(formData.sellingTime);
  }, [formData.sellingTime]);

  return (
    <div className="flex-col justify-center"> 
      <div className=" flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-4 items-center justify-center">
        <label
          htmlFor="sell"
          className={`cursor-pointer rounded-lg shadow-md w-[140px] h-[40px] px-4 py-2 text-center ${
            selectedReason === 'Sell' ? 'bg-blue-500 text-white' : 'bg-white'
          }`}
        >
          <input
            type="radio"
            id="sell"
            name="reason"
            value="Sell"
            className="hidden"
            checked={selectedReason === 'Sell'}
            onChange={handleReasonChange}
          />
          Sell
        </label>

        <label
          htmlFor="Refinance"
          className={`cursor-pointer rounded-lg shadow-md w-[140px] h-[40px] px-4 py-2 text-center ${
            selectedReason === 'Refinance' ? 'bg-blue-500 text-white' : 'bg-white'
          }`}
        >
          <input
            type="radio"
            id="Refinance"
            name="reason"
            value="Refinance"
            className="hidden"
            checked={selectedReason === 'Refinance'}
            onChange={handleReasonChange}
          />
          Refinance
        </label>


        <label
          htmlFor="just_estimate"
          className={`cursor-pointer rounded-lg shadow-md w-[140px] h-[40px] px-4 py-2 text-center ${
            selectedReason === 'Just Estimate' ? 'bg-blue-500 text-white' : 'bg-white'
          }`}
        >
          <input
            type="radio"
            id="just_estimate"
            name="reason"
            value="Just Estimate"
            className="hidden"
            checked={selectedReason === 'Just Estimate'}
            onChange={handleReasonChange}
          />
          Just Estimate
        </label>
      </div>

      {
  selectedReason === 'Sell' && (
    <div className="mt-14">
    <h2 className='sm:text-3xl text-center text-white font-[500] mb-4 text-lg '>When are you Planning to Sell?</h2>

      <div className="flex justify-center items-center">
      <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-2 items-center">
        <label
          htmlFor="asap"
          className={`cursor-pointer rounded-lg  shadow-md w-[140px] text-sm h-[40px] py-2 text-center ${
            selectedTime === 'ASAP' ? 'bg-blue-500 text-white' : 'bg-white'
          }`}
        >
          <input
            type="radio"
            id="asap"
            name="sellingTime"
            value="ASAP"
            className="hidden"
            checked={selectedTime === 'ASAP'}
            onChange={handleTimeChange}
          />
          ASAP
        </label>

        <label
          htmlFor="1-3"
          className={`cursor-pointer rounded-lg shadow-md w-[140px] text-sm h-[40px] py-2 text-center ${
            selectedTime === '1-3 Months' ? 'bg-blue-500 text-white' : 'bg-white'
          }`}
        >
          <input
            type="radio"
            id="1-3"
            name="sellingTime"
            value="1-3 Months"
            className="hidden"
            checked={selectedTime === '1-3 Months'}
            onChange={handleTimeChange}
          />
          1-3 Months
        </label>

        <label
          htmlFor="3-6"
          className={`cursor-pointer rounded-lg shadow-md w-[140px] text-sm h-[40px] py-2 text-center ${
            selectedTime === '3-6 Months' ? 'bg-blue-500 text-white' : 'bg-white'
          }`}
        >
          <input
            type="radio"
            id="3-6"
            name="sellingTime"
            value="3-6 Months"
            className="hidden"
            checked={selectedTime === '3-6 Months'}
            onChange={handleTimeChange}
          />
          3-6 Months
        </label>

        <label
          htmlFor="6-12"
          className={`cursor-pointer rounded-lg shadow-md w-[140px] text-sm h-[40px] py-2 text-center ${
            selectedTime === '6-12 Months' ? 'bg-blue-500 text-white' : 'bg-white'
          }`}
        >
          <input
            type="radio"
            id="6-12"
            name="sellingTime"
            value="6-12 Months"
            className="hidden"
            checked={selectedTime === '6-12 Months'}
            onChange={handleTimeChange}
          />
          6-12 Months
        </label>

        <label
          htmlFor="undecided"
          className={`cursor-pointer rounded-lg shadow-md w-[140px] text-sm h-[40px]  pxpx-14 py-2 text-center ${
            selectedTime === "Haven't Decided" ? 'bg-blue-500 text-white' : 'bg-white'
          }`}
        >
          <input
            type="radio"
            id="undecided"
            name="sellingTime"
            value="Haven't Decided"
            className="hidden"
            checked={selectedTime === "Haven't Decided"}
            onChange={handleTimeChange}
          />
          Haven't Decided
        </label>
      </div>
    </div>
    </div>
  )
}

    </div>
  );
};

export default Reason;
