import React from 'react';
import './App.css'; 
import Form from './Form';
import logo from './images/logo.png';
import Landing from './components/Landing';
import Free from '../src/images/free evaluation.png';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';


const year = new Date().getFullYear();

const Appp = () => {

  const handleContextMenu = (e) => {
    e.preventDefault(); // Prevent default context menu behavior
  };

  return (
    <div className="h-[90vh]">

<Helmet>
        <title>Discover Your Home's True Value - True Value Of My House</title>
        <meta
          name="description"
          content="Discover the True Worth of Your Property with TrueValueOfMyHouse - Expert & Accurate House Valuations for Informed Decisions"
        />
      </Helmet>

      <div className="background flex flex-col justify-center gap-5 items-center ">
        <div className="bg-black bg-opacity-75 w-full min-h-[90vh] pb-6 relative">
          {/* Free Image Here */}
          <img
            src={Free}
            className="absolute sm:w-[15%] w-[25%] ml-6 sm:mt-16 mt-6 animate-bounce"
            alt="Free Evaluation"
            onContextMenu={handleContextMenu}
          />

          <Link to="/testimonials" className='absolute top-1 right-1 sm:top-3 sm:right-3 text-white  hover:bg-[#ff8800] bg-[#ff9500] px-2 py-1 sm:text-lg text-sm rounded-md'>Testimonials</Link >

          <img
            className="sm:w-[15%] w-[35%] sm:pt-[0] pt-[10%] block mx-auto mb-12 mt-[5%]"
            src={logo}
            alt="Logo True Value of my House"
            onContextMenu={handleContextMenu}
          />
          <div className="flex flex-col justify-center gap-5 items-center">
            <div className="sm:w-1/2 w-[80%] mx-auto">
              <Form className="" />
            </div>
          </div>
        </div>
      </div>

      <div className="">
        <Landing />
      </div>

      <div className="h-8 bg-black flex justify-center items-center">
        <h3 className="text-[#ff9500]">Copy &copy; Right Reserved {year}</h3>
      </div>
    </div>
  );
};

export default Appp;
