import React from 'react'
import Appp from './Appp.jsx'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Testimonials from './components/Testimonials.jsx'
import ScrollToTop from './components/ScrollToTop .jsx'


const App = () => {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<Appp />} />
          <Route path="/testimonials" element={<Testimonials />} />
        </Routes>
        <ScrollToTop/>
      </Router> 
    </div>
  )
}

export default App
