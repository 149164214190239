export const faqs = [
    {
        ques: 'What is "home value"?',
        ans: "Home value is the amount buyers are willing to pay for a property, influenced by factors like location, condition, and market trends. Fair market value refers to the price an average buyer would pay under normal conditions, without personal attachments or unusual circumstances influencing the decision."
    },
    {
        ques: "How is my home's value determined?",
        ans: "Your home's value is estimated using online tools, professional appraisals, or comparative market analysis (CMA) by a real estate agent. Online tools use public data and recent sales for quick estimates. For accuracy, real estate professionals analyze your property's condition, local market trends, and comparable sales (similar homes recently sold) to provide a comprehensive valuation."
    },
    {
        ques: "What increases my home's value?",
        ans: "Several factors contribute to your home's value. External factors like a strong job market, desirable amenities (parks, shops, restaurants), and low housing inventory can boost demand and drive up prices. However, you have the power to enhance your home's value through strategic actions. Maintaining excellent curb appeal, investing in upgrades with good return on investment (ROI), and keeping your home in top condition are key. Timing your listing during peak selling seasons and ensuring your home is clean, decluttered, and well-staged can also significantly impact its perceived value. By focusing on these controllable factors, you can maximize your home's potential in the market. Let me know if you'd like to explore specific strategies for improving your home's value."
    },
    {
        ques: "How do free home value estimates work?",
        ans: "Free home value estimates use automated valuation models (AVMs) that analyze public records, user-submitted data, property listings, and sales through algorithms. These tools can quickly process vast amounts of information to generate an estimated value. However, real estate experts advise using these estimates as a starting point for understanding your home's potential value, and recommend consulting professionals for a more accurate assessment."
    },
    {
        ques: "How often should I check my home's value?",
        ans: "There's no strict rule, but checking periodically is wise. Monitoring your home's value online helps you understand its worth in the current market. This is crucial when selling, as it informs your listing price and negotiation strategy. Additionally, checking before major renovations helps assess potential return on investment, and keeping up with value fluctuations ensures your property insurance coverage is adequate."
    },
    {
        ques: "What's next after I receive my estimate?",
        ans: "After receiving your estimate, connect with a top real estate agent for a comparative market analysis (CMA). This will provide a more accurate valuation and help you develop a pricing strategy. Additionally, you can use the estimate to calculate selling costs and potential net proceeds from your home sale."
    }
    
]




export const testimonialsData = [
    {
        review: "Incredible resource! True Value of My House gave me a surprisingly accurate estimate that lined up with recent sales in my neighborhood. It is a game-changer.",
        name: 'Sofia Rodriguez'
    },
    {
        review: "Easy to use and so helpful. I was considering selling my house but had no idea where to start. True Value of My House gave me a solid starting point for my research. ",
        name: 'Nicholas Walker'
    },
    {
        review: "True Value of My House is a great resource. I was able to get an accurate estimate of my home's value in a matter of minutes. I highly recommend it to anyone looking to sell their home.",
        name: 'Jennifer Miller'
    },
    {
        review:"True Value of My House is a lifesaver for homeowners! Not only did it provide a free valuation, but it also offered insights into local market trends. I'm impressed. ",
        name: "Emily Davis"
    },
    {
        review: "I love the detailed breakdown of factors influencing my home's value. True Value of My House is so much more than just a number – it's a wealth of information. ",
        name: "David Smith"
    },
    {
        review: "Accurate, fast, and free! I couldn't ask for anything more from a home valuation tool. I'm highly recommending this site to friends and family. ",
        name: "Jennie Johnson"
    },
    {
        review: "As a first-time homebuyer, True Value of My House helped me understand the market and negotiate a fair price. This is an essential tool for anyone in the real estate market. ",
        name: "Michael B"
    },
    {
        review: "True Value of My House user-friendly interface and clear instructions made it easy to get a quick estimate. I'm so glad I found this resource. ",
        name: "Jessica Williams"
    },
    {
        review: "I was blown away by how comprehensive this free valuation is. It takes into account everything from square footage to recent renovations. I'm truly impressed. ",
        name: "Christopher Jones"
    },
    {
        review: "I was skeptical at first, but True Value of My House delivered! The valuation was spot-on, and the process was incredibly smooth. I'm a fan! ",
        name: "Ashley Miller"
    },
    {
        review: "I was hesitant to trust an online valuation, but True Value of My House reputation for accuracy is well-deserved. I'm so happy with the results I received. ",
        name: "Matthew Taylor"
    },
    {
        review: "True Value of My House gave me the confidence I needed to list my house at a fair price. I'm so grateful for this free resource. ",
        name: "Amanda Anderson"
    },
    {
        review: "I was amazed by how accurate the valuation was, especially considering it's a free service. I would definitely use it again. ",
        name: "Daniel Wilson"
    },
    {
        review: "True Value of My House is a must-have for any homeowner. It's a quick and easy way to get a realistic estimate of your home's worth. ",
        name: "Sarah Martinez"
    },
    {
        review: "I love how transparent True Value of My House is about the factors that influence home values. It's clear that they prioritize accuracy and fairness. ",
        name: "Andrew Thompson"
    },
    {
        review: "True Value of My House exceeded my expectations! The valuation was comprehensive and insightful, and it helped me make informed decisions about my property. ",
        name: "Omar Hassan"
    },
    {
        review: "As a real estate agent, I often recommend True Value of My House to my clients. It's a valuable tool for understanding the local market and making informed decisions. ",
        name: "Brittany Moore"
    },
    // {
    //     review: "I was impressed by how quickly I received my valuation. The process was seamless, and the results were delivered in a clear and concise format. ",
    //     name: "Joshua Clark"
    // },

    
    
]