import React from 'react';
import landingImg from '../images/srch1.jpg';
import landingImg2 from '../images/srch2.jpg';
import { Accordion } from "flowbite-react";
import { faqs } from '../data/Data';
import logo from '../images/logo.png'
import { AiFillFacebook } from "react-icons/ai";
import { AiFillTikTok, AiFillInstagram, AiFillLinkedin   } from "react-icons/ai";

const Landing = () => {
  const handleContextMenu = (e) => {
    e.preventDefault(); // Prevent default context menu behavior
  };

  return (
    <div className="">
      <div className='max-w-[1170px] mx-auto overflow-x-hidden bg-gray-100 min-h-screen sm:pt-10 pt-4 '>
     <div className="px-2 sm:px-6">


     <div className="mt-16 sm:mt-1 mx-auto block w-full  mb-16">
<video onContextMenu={handleContextMenu} src="https://res.cloudinary.com/ddn3plrim/video/upload/v1716562573/lv_0_20240524174903_1_xmauox.mp4" controls ></video>
</div>



     <div className="flex flex-col sm:flex-row items-center">
        <div className="sm:w-1/2 sm:mb-0 mb-5">
          <h2 className="text-2xl text-[#ff9500] sm:text-3xl font-[600] mb-4">The Real Worth of Your Home: Personalized Insights & Market Data</h2>
          <p className="sm:text-lg text-[1rem] text-slate-800 mb-3 sm:mb-6">Tired of inaccurate home value estimates? Most online tools rely on algorithms that can't capture the unique details that make your home special. That's where "True Value of My House" comes in. We combine your insider knowledge with comprehensive real estate market data to deliver a personalized and highly accurate home valuation.</p>
          <a
            href="#"
            className="hover:bg-[#ff9500] hover:text-slate-700  bg-slate-700 text-[0.75rem] sm:text-2xl text-white w-fit py-1 px-4 rounded transition duration-300 ease-in-out"
            
          >
            Get Evaluation
          </a>
        </div>
        <div className="sm:w-1/2">
          <img onContextMenu={handleContextMenu} className="w-full sm:w-[100%] block ml-auto shadow-2xl shadow-black rounded-lg" src={landingImg} alt="" />
        </div>
      </div>

      <div className="flex flex-col-reverse sm:flex-row items-center mt-16 ">
        <div className="sm:w-1/2">
          <img onContextMenu={handleContextMenu} className="w-full sm:w-[95%] block mr-auto shadow-2xl shadow-black rounded-lg" src={landingImg2} alt="" />
        </div>
        <div className="sm:w-1/2 sm:mb-0 mb-5">
          <h2 className="text-2xl text-[#ff9500] sm:text-3xl font-[600] mb-4">Expert Guidance for Maximum Returns</h2>
          <p className="sm:text-lg text-[1rem] text-slate-800 mb-3 sm:mb-6">
            Get access to a curated list of top-performing local real estate agents who specialize in selling homes like yours. These experienced professionals have a proven track record of maximizing sale prices and ensuring a smooth transaction.            </p>
          <a
            href="#"
            className="hover:bg-[#ff9500] bg-slate-700 text-[0.75rem] sm:text-2xl text-white hover:text-slate-700 w-fit py-1 px-4 rounded transition duration-300 ease-in-out"
           
          >
            Get Started
          </a>
        </div>
      </div>



      <div className="mt-16 sm:mt-24 mx-auto block w-full sm:w-[60%] mb-16">
          <h2 className='text-2xl text-[#ff9500] sm:text-3xl font-[600] mb-4'>FAQs</h2>
          <div>
            {faqs.map((faq, index) => (
              <Accordion key={index} collapseAll className=''>
                <Accordion.Panel>
                  <Accordion.Title className="flex items-center justify-between text-[1rem] sm:text-xl text-slate-800 text-start font-[500] px-2 py-3 border-b-2 border-slate-300">
                    {faq.ques} 
                    {/* Arrow will now be automatically positioned correctly */}
                  </Accordion.Title>
                  <Accordion.Content>
                    <p className="text-[1rem] text-slate-800 px-2 py-1 font-[300]">{faq.ans}</p>
                  </Accordion.Content>
                </Accordion.Panel>
              </Accordion>
            ))}
          </div>
        </div>
      
     </div>
    </div>



    <div className="  bg-black py-4 px-2">
        <div className="max-w-[1170px] mx-auto">
        <div className="flex flex-col sm:flex-row  sm:items-start">

<div className="sm:w-1/2 mt-2">
  <img src={logo} className='sm:w-[55%] w-[50%] mx-auto sm:mx-0' alt="Logo True Value of My House" onContextMenu={handleContextMenu} />
</div>

<div className="sm:w-2/2 mt-8 sm:mt-0">
  <h3 className='text-xl sm:text-2xl font-[200] text-[#ff9500] text-center'>Privacy Disclaimer</h3>
  <p className='text-[0.75rem] text-slate-50 font-[200] text-center'>“True Value of my House” respects your privacy. We do not sell, rent, or share your personal information, including your email address and phone number, with any third parties for marketing purposes. The information you provide is used solely for the purpose of responding to your inquiries and providing you with real estate services.</p>
</div>

<div className="sm:w-1/2 mt-8 sm:mt-0 ">
  <h3 className='text-xl sm:text-2xl font-[200] text-[#ff9500] sm:text-end text-center'>Contact Us:</h3>
  <p className='text-[0.75rem] text-slate-50 font-[200] sm:text-end text-center'>
    <a href='mailto:info@truevalueofmyhouse.com'>info@truevalueofmyhouse.com</a>
  </p>

  <div className='flex flex-wrap justify-center sm:justify-end gap-2'>
    <a href='https://www.facebook.com/truevalueofmyhouse/' target='_blank' className='mt-2 w-fit text-[1.75rem] hover:text-slate-50 text-[#ff9500] font-[200]'><AiFillFacebook /></a>
    <a href='https://www.tiktok.com/@truehousevalue' target='_blank' className='mt-2 w-fit text-[1.75rem] hover:text-slate-50 text-[#ff9500] font-[200]'><AiFillTikTok /></a>
    <a href='#' target='_blank' className='mt-2 w-fit text-[1.75rem] hover:text-slate-50 text-[#ff9500] font-[200]'><AiFillInstagram /></a>
    <a href='https://www.linkedin.com/company/true-value-of-my-house/' target='_blank' className='mt-2 w-fit text-[1.75rem] hover:text-slate-50 text-[#ff9500] font-[200]'><AiFillLinkedin /></a>
  </div>
</div>



</div>
        </div>
      </div>
    </div>
  );
};

export default Landing;
