import React, { useEffect, useRef } from 'react';

const apiKey = "AIzaSyBxGzkNl-UJi9fvk-3CINzJ2fjvwD0spUo";
const mapApiJs = "https://maps.googleapis.com/maps/api/js";

function loadAsyncScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    Object.assign(script, {
      type: "text/javascript",
      async: true,
      src,
    });
    script.addEventListener("load", () => resolve(script));
    document.head.appendChild(script);
  });
}

const Search = ({ formData, setFormData }) => {
  const searchInput = useRef(null);

  const initMapScript = () => {
    if (window.google) {
      return Promise.resolve();
    }

    // Check if the script with the Google Maps API is already loaded
    const existingScript = document.querySelector('script[src*="maps.googleapis.com/maps/api/js"]');
    if (existingScript) {
      return Promise.resolve();
    }

    // If not loaded, load the script
    const src = `${mapApiJs}?key=${apiKey}&libraries=places&v=weekly`;
    return loadAsyncScript(src);
  };

  const initAutoComplete = () => {
    if (!searchInput.current || !window.google) return;

    const waitForGoogle = setInterval(() => {
      if (window.google.maps.places) {
        clearInterval(waitForGoogle);

        const autoComplete = new window.google.maps.places.Autocomplete(
          searchInput.current,
          {
            componentRestrictions: { country: "us" },
            autoComplete: true,
          }
        );

        autoComplete.setFields(["formatted_address", "address_component", "geometry"]);

        autoComplete.addListener("place_changed", () => {
          const place = autoComplete.getPlace();
          if (!place || !place.formatted_address) return;

          setFormData({
            ...formData,
            from_location: place.formatted_address,
          });
        });
      }
    }, 100);
  };

  useEffect(() => {
    initMapScript().then(() => initAutoComplete());
  }, []);

  return (
    <div>
<input
  type="text"
  placeholder="Example: 123 Main St, New York NY 10001"
  className="w-full sm:w-[85%] p-3 focus:outline-none mx-auto block rounded-[5px]"
  name="from_location"
  ref={searchInput}
  value={formData.from_location}
  required
  onChange={(event) =>
    setFormData({ ...formData, from_location: event.target.value })}
  onKeyDown={(event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  }}
/>
    </div>
  );
};

export default Search;
