import React, { useState, useEffect } from 'react';

const ProppertyType = ({ formData, setFormData }) => {
  const [selectedPropertyType, setSelectedPropertyType] = useState(formData.propertyType);

  const handlePropertyTypeChange = (event) => {
    const selectedPropertyType = event.target.value;
    setFormData({
      ...formData,
      propertyType: selectedPropertyType,
    });
    setSelectedPropertyType(selectedPropertyType);
  };

  useEffect(() => {
    setSelectedPropertyType(formData.propertyType);
  }, [formData.propertyType]);

  return (
    <div className="flex justify-center items-center"> 
      <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-4 items-center">
      <label
        htmlFor="singleFamily Home"
        className={`cursor-pointer rounded-lg shadow-md w-[180px] h-[40px] px-4 py-2 text-center ${
          selectedPropertyType === 'Single Family Home' ? 'bg-blue-500 text-white' : 'bg-white'
        }`}
      >
        <input
          type="radio"
          id="singleFamily Home"
          name="propertyType"
          value="Single Family Home"
          className="hidden"
          checked={selectedPropertyType === 'Single Family Home'}
          onChange={handlePropertyTypeChange}
        />
        Single Family Home
      </label>

      <label
        htmlFor="multiFamily"
        className={`cursor-pointer rounded-lg shadow-md w-[180px] h-[40px] px-4 py-2 text-center ${
          selectedPropertyType === 'Multi-family' ? 'bg-blue-500 text-white' : 'bg-white'
        }`}
      >
        <input
          type="radio"
          id="multiFamily"
          name="propertyType"
          value="Multi-family"
          className="hidden"
          checked={selectedPropertyType === 'Multi-family'}
          onChange={handlePropertyTypeChange}
        />
        Multi-family
      </label>

      <label
        htmlFor="Condominium"
        className={`cursor-pointer rounded-lg shadow-md w-[180px] h-[40px] px-4 py-2 text-center ${
          selectedPropertyType === 'Condominium' ? 'bg-blue-500 text-white' : 'bg-white'
        }`}
      >
        <input
          type="radio"
          id="Condominium"
          name="propertyType"
          value="Condominium"
          className="hidden"
          checked={selectedPropertyType === 'Condominium'}
          onChange={handlePropertyTypeChange}
        />
        Condominium
      </label>

      <label
        htmlFor="townhouse"
        className={`cursor-pointer rounded-lg shadow-md w-[180px] h-[40px] px-4 py-2 text-center ${
          selectedPropertyType === 'Townhouse' ? 'bg-blue-500 text-white' : 'bg-white'
        }`}
      >
        <input
          type="radio"
          id="townhouse"
          name="propertyType"
          value="Townhouse"
          className="hidden"
          checked={selectedPropertyType === 'Townhouse'}
          onChange={handlePropertyTypeChange}
        />
        Townhouse
      </label>
    </div>
    </div>
  );
};

export default ProppertyType;
