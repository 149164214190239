import React, { useState, useEffect } from 'react';

const CurrentAgent = ({ formData, setFormData }) => {
  const [selectedOption, setSelectedOption] = useState(formData.currentAgent);

  const handleOptionChange = (event) => {
    const selectedOption = event.target.value;
    setFormData({
      ...formData,
      currentAgent: selectedOption,
    });
    setSelectedOption(selectedOption);
  };

  useEffect(() => {
    setSelectedOption(formData.currentAgent);
  }, [formData.currentAgent]);

  return (
   <div className="flex justify-center items-center">
     <div className="flex items-center space-x-4">
      <label
        htmlFor="Yes"
        className={`cursor-pointer rounded-lg shadow-md w-[140px] h-[40px] px-4 py-2 text-center ${
          selectedOption === 'Yes' ? 'bg-blue-500 text-white' : 'bg-white'
        }`}
      >
        <input
          type="radio"
          id="Yes"
          name="currentAgent"
          value="Yes"
          className="hidden"
          checked={selectedOption === 'Yes'}
          onChange={handleOptionChange}
        />
        Yes
      </label>

      <label
        htmlFor="No"
        className={`cursor-pointer rounded-lg shadow-md w-[140px] h-[40px] px-4 py-2 text-center ${
          selectedOption === 'No' ? 'bg-blue-500 text-white' : 'bg-white'
        }`}
      >
        <input
          type="radio"
          id="No"
          name="currentAgent"
          value="No"
          className="hidden"
          checked={selectedOption === 'No'}
          onChange={handleOptionChange}
        />
        No
      </label>
    </div>
   </div>
  );
};

export default CurrentAgent;
