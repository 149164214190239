import React, { useState, useEffect } from 'react';

const Condition = ({ formData, setFormData }) => {
  const [selectedCondition, setSelectedCondition] = useState(formData.condition);

  const handleConditionChange = (event) => {
    const selectedCondition = event.target.value;
    setFormData({
      ...formData,
      condition: selectedCondition,
    });
    setSelectedCondition(selectedCondition);
  };

  useEffect(() => {
    setSelectedCondition(formData.condition);
  }, [formData.condition]);

  return (
    <div className="flex justify-center items-center">
      <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-4 items-center">
      <label
        htmlFor="new"
        className={`cursor-pointer rounded-lg shadow-md w-[200px] h-[40px] px-4 py-2 text-center ${
          selectedCondition === 'New' ? 'bg-blue-500 text-white' : 'bg-white'
        }`}
      >
        <input
          type="radio"
          id="new"
          name="condition"
          value="New"
          className="hidden"
          checked={selectedCondition === 'New'}
          onChange={handleConditionChange}
        />
        New
      </label>

      <label
        htmlFor="needwork"
        className={`cursor-pointer rounded-lg shadow-md w-[200px] text-center h-[40px] px-4 py-2 ${
          selectedCondition === 'Needs a little Work' ? 'bg-blue-500 text-white' : 'bg-white'
        }`}
      >
        <input
          type="radio"
          id="needwork"
          name="condition"
          value="Needs a little Work"
          className="hidden"
          checked={selectedCondition === 'Needs a little Work'}
          onChange={handleConditionChange}
        />
        Needs a little work
      </label>

      <label
        htmlFor="needsigwork"
        className={`cursor-pointer rounded-lg shadow-md w-[200px] text-center h-[40px] px-4 py-2 ${
          selectedCondition === 'Needs Significant work' ? 'bg-blue-500 text-white' : 'bg-white'
        }`}
      >
        <input
          type="radio"
          id="needsigwork"
          name="condition"
          value="Needs Significant work"
          className="hidden"
          checked={selectedCondition === 'Needs Significant work'}
          onChange={handleConditionChange}
        />
        Needs significant work
      </label>

      <label
        htmlFor="teardown"
        className={`cursor-pointer rounded-lg shadow-md w-[200px] text-center h-[40px] px-4 py-2 ${
          selectedCondition === 'Gut-Job' ? 'bg-blue-500 text-white' : 'bg-white'
        }`}
      >
        <input
          type="radio"
          id="teardown"
          name="condition"
          value="Gut-Job"
          className="hidden"
          checked={selectedCondition === 'Gut-Job'}
          onChange={handleConditionChange}
        />
        Gut-Job
      </label>
    </div>
    </div>
  );
};

export default Condition;
