import React, { useState, useEffect } from 'react';

const Report = ({ formData, setFormData }) => {
  const [selectedReport, setSelectedReport] = useState(formData.report);

  const handleReportChange = (event) => {
    const selectedReport = event.target.value;
    setFormData({
      ...formData,
      report: selectedReport,
    });
    setSelectedReport(selectedReport);
  };

  useEffect(() => {
    setSelectedReport(formData.report);
  }, [formData.report]);

  return (
    <div className="flex justify-center"> 
      <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-4 items-center">
        <label
          htmlFor="SMS"
          className={`cursor-pointer rounded-lg shadow-md w-[140px] h-[40px] px-4 py-2 text-center ${
            selectedReport === 'SMS' ? 'bg-blue-500 text-white' : 'bg-white'
          }`}
        >
          <input
            type="radio"
            id="SMS"
            name="report"
            value="SMS"
            className="hidden"
            checked={selectedReport === 'SMS'}
            onChange={handleReportChange}
          />
          SMS
        </label>

        <label
          htmlFor="WhatsApp"
          className={`cursor-pointer rounded-lg shadow-md w-[140px] h-[40px] px-4 py-2 text-center ${
            selectedReport === 'WhatsApp' ? 'bg-blue-500 text-white' : 'bg-white'
          }`}
        >
          <input
            type="radio"
            id="WhatsApp"
            name="report"
            value="WhatsApp"
            className="hidden"
            checked={selectedReport === 'WhatsApp'}
            onChange={handleReportChange}
          />
          WhatsApp
        </label>


        <label
          htmlFor="Email"
          className={`cursor-pointer rounded-lg shadow-md w-[140px] h-[40px] px-4 py-2 text-center ${
            selectedReport === 'Email' ? 'bg-blue-500 text-white' : 'bg-white'
          }`}
        >
          <input
            type="radio"
            id="Email"
            name="report"
            value="Email"
            className="hidden"
            checked={selectedReport === 'Email'}
            onChange={handleReportChange}
          />
          Email
        </label>
      </div>
    </div>
  );
};

export default Report;
