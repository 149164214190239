import React, { useState } from 'react';
import logo from '../images/logo.png';
import { Link } from 'react-router-dom';
import { testimonialsData } from '../data/Data';
import { FaQuoteLeft } from "react-icons/fa";
import { Helmet } from 'react-helmet';


const Testimonials = () => {
  const [showAll, setShowAll] = useState(false);

  const displayedTestimonials = showAll ? testimonialsData : testimonialsData.slice(0, 5);

  const handleToggle = () => {
    setShowAll(!showAll);
  };

  return (
    <div>
      <div className="bg-black py-4 px-2">

      <Helmet>
        <title>True Value of My House - Client Testimonials</title>
        <meta
          name="description"
          content="Read what our satisfied clients have to say about their experience with True Value of My House. Discover the true value of your home today."
        />
      </Helmet>
        <div className="max-w-[1170px] mx-auto">
          <div className="flex justify-center items-center flex-wrap">
            <div className="w-full sm:w-1/3 mt-2 sm:mt-0 flex justify-center sm:justify-start">
              <img src={logo} className='w-32 sm:w-40 sm:block hidden' alt="Logo True Value of My House" />
            </div>

            <div className="w-full sm:w-1/3 mt-4 sm:mt-0">
              <h1 className='text-2xl sm:text-3xl font-semibold text-[#ff9500] text-center'>What Our Clients Say</h1>
            </div>

            <div className="w-full sm:w-1/3 mt-4 sm:mt-0 flex flex-col items-center sm:items-end">
              <Link to="/" className='hidden sm:flex justify-end w-fit ml-auto text-white hover:bg-[#ff8800] bg-[#ff9500] px-2 py-1 sm:text-lg text-sm rounded-md'>Get Free Evaluation</Link>
            </div>
          </div>
        </div>
      </div>
      <Link to="/" className='sm:hidden absolute top-1 right-1 sm:top-3 sm:right-3 text-white hover:bg-[#ff8800] bg-[#ff9500] px-2 py-1 sm:text-lg text-sm rounded-md'>Get Free Evaluation</Link>

      <div className="container max-w-[1170px] min-h-screen bg-gray-200 mx-auto px-4 py-12">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {displayedTestimonials.map((testimonial, index) => (
            <div key={index} className="bg-white p-6 rounded-lg shadow-lg transform transition-transform hover:scale-105">
              <p><FaQuoteLeft className='text-[#ff9500] sm:text-2xl text-lg' /></p>
              <p className="text-gray-800 text-lg mb-4 italic text-justify">&nbsp; &nbsp;{testimonial.review}</p>
              <h3 className="text-[#ff9500] text-xl font-semibold text-right">- {testimonial.name}</h3>
            </div>
          ))}
        </div>
        {testimonialsData.length > 5 && (
          <div className="text-center mt-8">
            <button onClick={handleToggle} className="text-white hover:bg-[#ff8800] bg-[#ff9500] px-4 py-2 rounded-md">
              {showAll ? 'Show Less' : 'View All Reviews'}
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default Testimonials;
