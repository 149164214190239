import React from 'react'
import { SiTicktick } from "react-icons/si";


const Success = () => {
  return (
    <div>
      <h2 className='sm:text-[2rem] text-white font-[600] mb-4 text-xl text-center'>
      <SiTicktick className='mx-auto text-[13vh]'/>

        <br />
        Thanks for submitting your evaluation request.
        <br />
        Our acquisition team will contact you soon.
      </h2>
      <a href='/' className=' block mx-auto bg-[#ff9500] hover:bg-[#ff8800] text-[0.75rem] sm:text-2xl text-white w-fit  py-2 px-4  rounded'>Send another evaluation report</a>
    </div>
  )
}

export default Success
